import axios, { AxiosError } from "axios";
import AppointmentsForm from "components/Shared/Appointments/AppointmentsForm/AppointmentsForm";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { checkDateValidation } from "shared/helpers/common.helper";
import { editAppointment, getAppointmentData } from "shared/Services";
import { DeleteAppointment } from "shared/Services/Appointments.service";

import { AppointmentCreateFormI, AppointmentDataI } from "@interfaces";

import styles from "./AppointmentsEdit.module.scss";
import { toast } from "react-toastify";

function AppointmentsEdit() {
  const { currentUser } = useContext(UserDataContext);
  const [appointmentInfo, setAppointmentInfo] = useState<AppointmentDataI | null>(null);
  const navigate = useNavigate();
  const params = useParams();

  const [submitted, setSubmitted] = useState(0);

  const FormSubmit = (value: AppointmentCreateFormI) => {
    if (!value.isAllDay && !checkDateValidation(value.time!.from, value.time!.to)) {
      toast.error("End time can't be earlier than start time");
    } else {
      editAppointment(params.id!, value, currentUser!.timezone.value).then(
        (response: AxiosError | any) => {
          if (axios.isAxiosError(response)) {
            toast.error(response.response?.data?.errors[0]?.detail);
          } else {
            toast.success("Successfully completed!");
            navigate(`/`);
          }
        }
      );
    }
  };

  useEffect(() => {
    params.id &&
      getAppointmentData(params.id).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          setAppointmentInfo(data);
        }
      });
  }, []);

  const ToggleDelete = () => {
    params.id &&
      DeleteAppointment(params.id).then((data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          toast.success("Successfully deleted!");
          navigate(`/visits/calendar`);
        }
      });
  };
  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        deleteToggle={() => ToggleDelete()}
        title="Edit Appointment"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        onCancelToggle={() => navigate(-1)}
        viewDeleteButton={true}
      />
      {appointmentInfo && (
        <AppointmentsForm
          submitted={submitted}
          formFinished={FormSubmit}
          formValue={appointmentInfo}
        ></AppointmentsForm>
      )}
    </div>
  );
}

export default AppointmentsEdit;
