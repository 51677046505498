import axios, { AxiosError } from "axios";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CountriesStatesContext, ManagerContext, UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import {
  formatAddressToString,
  GetStateOrCountryCode,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import { getLocationsList } from "shared/Services";

import { LocationsInfoI, LocationsListI } from "@interfaces";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";

import styles from "./LocationsList.module.scss";
import { toast } from "react-toastify";
import { debounce } from "shared/helpers/debounce";

function LocationsList({
  locationSelected,
  active,
  hasSearch = false,
  companyId,
}: {
  locationSelected: (location: LocationsInfoI) => void;
  active?: string;
  hasSearch?: boolean;
  companyId?: string;
}) {
  const { countriesStates } = useContext(CountriesStatesContext);
  const { currentUser } = useContext(UserDataContext);
  const [{ agent }] = useContext(ManagerContext);

  const [content, setContent] = useState<LocationsListI[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (searchText === "" || pageNumber !== 0) {
      getLocationsList(pageNumber * 20, searchText, {
        companyId,
        filterByAgent: agent
          ? agent.uuid
          : !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
              isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceLocation])
            ? currentUser!.uid
            : undefined,
        filterByUser:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
          !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceLocation]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnLocation])
            ? currentUser!.uid
            : undefined,
      }).then((data) => {
        responseMap(data);
      });
    } else {
      setIsLoading(true);
      debouncedCallEndpointRef.current(searchText, countriesStates);
    }
  }, [pageNumber, searchText]);

  const debouncedCallEndpointRef = useRef(
    debounce((searchText, countriesStates) => {
      getLocationsList(0, searchText, {
        companyId,
        filterByAgent: agent
          ? agent.uuid
          : !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
              isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceLocation])
            ? currentUser!.uid
            : undefined,
        filterByUser:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
          !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceLocation]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnLocation])
            ? currentUser!.uid
            : undefined,
        countriesStates: GetStateOrCountryCode(searchText, countriesStates),
      }).then((data) => {
        responseMap(data);
      });
    }, 500)
  );

  const responseMap = (data: AxiosError | any) => {
    if (axios.isAxiosError(data)) {
      toast.error(
        data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : ""
      );
    } else {
      if (pageNumber === 0) {
        setContent([...data.content]);
      } else {
        if (
          content.length &&
          data.content.length &&
          content[content.length - 1].letter === data.content[0].letter
        ) {
          content[content.length - 1].locations.push(...data.content[0].locations);
          data.content.shift();
        }
        setContent([...content, ...data.content]);
      }
      setHasMore(data.hasMore);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setPageNumber(0);
  }, [searchText]);

  return (
    <div className={styles["up-locations"]}>
      {hasSearch && (
        <TextField
          hiddenLabel={true}
          fullWidth={true}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            classes: {
              root: styles["up-search-root"],
              input: styles["up-search-input"],
            },
            endAdornment: (
              <InputAdornment
                position={"end"}
                classes={{ root: styles["up-search-icon"] }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant={"filled"}
          placeholder={"Search"}
        />
      )}
      <LoadingSpinner isLoading={isLoading}>
        <div id="locations-scrollable" className={styles["up-locations-scrollable"]}>
          <InfiniteScroll
            scrollableTarget={"locations-scrollable"}
            dataLength={content.reduce((sum, item) => sum + item.locations.length + 1, 0)}
            style={{ overflow: "hidden" }}
            next={() => setPageNumber(pageNumber + 1)}
            hasMore={hasMore}
            loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
          >
            {content.length ? (
              content.map((i, index) => (
                <div className={styles["up-locations-block"]} key={index}>
                  <h2 className={styles["up-locations-letter"]}>{i.letter}</h2>
                  {i.locations.map((l, index) => (
                    <div
                      onClick={() => locationSelected(l)}
                      className={`${styles["up-locations-item"]} ${
                        active === l.id ? styles["active"] : ""
                      }`}
                      key={index}
                    >
                      <div className={styles["up-locations-title"]}>{l.title}</div>
                      <div className={styles["up-locations-details"]}>
                        {formatAddressToString(l.address, countriesStates)}
                      </div>
                      <div className={styles["up-locations-details"]}>{l.company}</div>
                      <div className={styles["up-locations-details"]}>
                        {l.contacts?.join(", ")}
                      </div>
                      {l.logo && (
                        <img
                          src={process.env.REACT_APP_BASE_URL! + l.logo}
                          alt={l.title}
                          className={styles["up-locations-logo"]}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <div className="up-no-items">No location records found</div>
            )}
          </InfiniteScroll>
        </div>
      </LoadingSpinner>
    </div>
  );
}

export default LocationsList;
