import { createContext, Dispatch, SetStateAction } from "react";

import { DaySchedulePageNewI, LatLngI } from "@interfaces";

export interface RouterContextI {
  opened: boolean;
  loaded: boolean;
  myLocation?: LatLngI;
  routeData?: DaySchedulePageNewI;
  gMapOpened: boolean;
  gMapGeolocation?: LatLngI;
  gMapLocationName?: string;
  isLocationLoaded?: boolean;
  isLocationHasError?: boolean;
}
export const RouterContext = createContext<
  [RouterContextI, Dispatch<SetStateAction<RouterContextI>>]
>([{ opened: false, loaded: false, gMapOpened: false }, () => {}]);
