import { AxiosResponse } from "axios";

import { helperApi, jsonAuthApi } from "./Settings.api.service";
import { Moment } from "moment-timezone";

export const apiCreateAppointment = (body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.post(`/ups_appointment/ups_appointment`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiEditAppointment = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/ups_appointment/ups_appointment/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiDeleteAppointment = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.delete(`/ups_appointment/ups_appointment/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetMissedAppointments = (
  date: string
): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`appointment/missed`, {
    params: {
      date,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetAppointmentsList = (
  date: string,
  uid: string
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_appointment/ups_appointment`, {
    params: {
      include: "location,uid",
      "filter[uid.id]": uid,
      "filter[status]": "1",
      "filter[date]": date,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetAppointmentsByMonth = (
  date: Moment,
  uid: string
): Promise<AxiosResponse<any, any>> => {
  const firstDay = date.clone().startOf("month");
  const lastDay = firstDay.clone().add(1, "M");
  return jsonAuthApi.get(`/ups_appointment/ups_appointment`, {
    params: {
      include: "uid",
      "filter[uid.id]": uid,
      "filter[status]": "1",
      "filter[date][operator]": "BETWEEN",
      "filter[date][value][0]": firstDay.format(),
      "filter[date][value][1]": lastDay.format(),
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetAppointmentData = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_appointment/ups_appointment/${id}`, {
    params: {
      include: "location",
    },
  });
};
