import { createContext, Dispatch, SetStateAction } from "react";

import { RoutingSettingsI } from "@interfaces";

export interface UserDataContextI {
  name: string;
  roles: string[];
  uid: string;
  permissions: string[];
  agentProfiles?: boolean;
  timezone: { value: string; offset: number };
  routerSettings: RoutingSettingsI;
}
export const UserDataContext = createContext<{
  currentUser: UserDataContextI | null;
  setCurrentUser: Dispatch<SetStateAction<UserDataContextI | null>>;
  login: (userData: UserDataContextI) => void;
  logout: () => void;
}>({
  currentUser: null,
  login: () => {},
  setCurrentUser: () => {},
  logout: () => {},
});
