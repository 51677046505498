import axios from "axios";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { checkDate12AMto00 } from "shared/helpers/time.helper";
import { getAllNotesList, getNotesList } from "shared/Services";

import { AllNotesViewI, NoteCardI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Fab, InputAdornment, TextField } from "@mui/material";

import styles from "./AllNotesView.module.scss";
import { toast } from "react-toastify";

function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function AllNotesView() {
  const { currentUser } = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState(true);

  const [content, setContent] = useState<AllNotesViewI[]>([]);
  const [searchContent, setSearchContent] = useState<NoteCardI[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getContent();
  }, [pageNumber, searchText]);

  const reload = () => {
    setIsLoading(true);
    setContent([]);
    setSearchContent([]);
    setPageNumber(0);
    setHasMore(true);
    getContent();
  };
  const getContent = () => {
    setHasMore(false);
    if (pageNumber === 0) {
      setIsLoading(true);
    }
    if (searchText === "") {
      getAllNotesList(pageNumber * 20, {
        filterByAgents:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceLocation])
            ? currentUser!.uid
            : undefined,
        filterByUser:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
          !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceLocation]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnLocation])
            ? currentUser!.uid
            : undefined,
      }).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          setContent([...content, ...data.content]);
          setSearchContent([]);
          setHasMore(data.hasMore);
          setIsLoading(false);
        }
      });
    } else {
      debouncedCallEndpointRef.current(searchText, pageNumber, searchContent);
    }
  };
  const debouncedCallEndpointRef = useRef(
    debounce((searchText, pageNumber, searchContent) => {
      getNotesList(pageNumber * 20, searchText, {
        userId:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
          !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
            ? currentUser!.uid
            : undefined,
        serviceId:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote])
            ? currentUser!.uid
            : undefined,
      }).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          if (pageNumber !== 0) {
            setSearchContent([...searchContent, ...data.content]);
          } else {
            setSearchContent([...data.content]);
          }
          setContent([]);
          setHasMore(data.hasMore);
          setIsLoading(false);
        }
      });
    }, 500)
  );

  useEffect(() => {
    setPageNumber(0);
  }, [searchText]);

  return (
    <div className={styles["up-container"]}>
      <Header
        title={"All Notes"}
        toggleBack={() => navigate("/")}
        toggleReload={reload}
      />
      <TextField
        hiddenLabel={true}
        fullWidth={true}
        onChange={(e) => setSearchText(e.target.value)}
        InputProps={{
          classes: {
            root: styles["up-search-root"],
            input: styles["up-search-input"],
          },
          endAdornment: (
            <InputAdornment position={"end"} classes={{ root: styles["up-search-icon"] }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant={"filled"}
        placeholder={"Search"}
      />

      <LoadingSpinner isLoading={isLoading} height={300}>
        <div className={styles["up-content"]}>
          {!searchText ? (
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={content.length}
              next={() => setPageNumber(pageNumber + 1)}
              hasMore={hasMore}
              loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
            >
              {content.length ? (
                content.map((i) => (
                  <div
                    className={styles["up-content-card"]}
                    key={i.locationId}
                    onClick={() => navigate(`location/${i.locationId}`)}
                  >
                    <div className={styles["up-content-card-header"]}>
                      <h2 className={styles["up-content-card-header-title"]}>
                        {i.locationName}
                      </h2>
                      {i.count && (
                        <span className={styles["up-content-card-header-count"]}>
                          {i.count}
                        </span>
                      )}
                    </div>

                    <p className={`up-truncate-6`}>{i.lastNote}</p>
                  </div>
                ))
              ) : (
                <div className="up-no-items">No note records found</div>
              )}
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={searchContent.length}
              next={() => setPageNumber(pageNumber + 1)}
              hasMore={hasMore}
              loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
            >
              {searchContent.length ? (
                searchContent.map((i) => (
                  <div
                    className={styles["up-content-card"]}
                    key={i.id}
                    onClick={() => navigate(`/notes/${i.id}`)}
                  >
                    <div className={styles["up-content-card-header"]}>
                      <div className={styles["up-content-card-header-left"]}>
                        {i.task && (
                          <span className={styles["up-content-card-header-task"]}>
                            {i.task}
                          </span>
                        )}
                        {i.location && (
                          <span className={styles["up-content-card-header-location"]}>
                            {i.location}
                          </span>
                        )}
                      </div>
                      <span className={styles["up-content-card-header-date"]}>
                        {checkDate12AMto00(i.date, currentUser!.timezone.value)}
                      </span>
                    </div>
                    <p className={`${styles["content"]} up-truncate-6`}>{i.content}</p>
                    <div className={styles["up-content-card-footer"]}>
                      <span className={styles["up-content-card-footer-author"]}>
                        {i.author}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="up-no-items">No note records found</div>
              )}
            </InfiniteScroll>
          )}
        </div>
      </LoadingSpinner>
      {isUserHavePermissions(currentUser!, [PermissionsList.createNote]) && (
        <Fab
          aria-label={"add task"}
          color={"inherit"}
          classes={{ root: styles["up-fab-root"] }}
          onClick={() => navigate("/notes/create")}
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
}

export default AllNotesView;
