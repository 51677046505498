import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import AllNotesView from "./AllNotesView/AllNotesView";
import NoteCreate from "./NoteCreate/NoteCreate";
import NoteEdit from "./NoteEdit/NoteEdit";
import NotesView from "./NotesView/NotesView";
import NoteView from "./NoteView/NoteView";
import { ErrorBoundary } from "error";

function NotesRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <Routes>
      {isUserHavePermissions(currentUser!, [
        PermissionsList.viewAnyLocation,
        PermissionsList.viewOwnLocation,
        PermissionsList.viewMyServiceLocation,
      ]) &&
        isUserHavePermissions(currentUser!, [
          PermissionsList.viewAnyNote,
          PermissionsList.viewOwnNote,
          PermissionsList.viewMyServiceNote,
        ]) && (
          <>
            <Route
              path="/location/:locationId"
              element={
                <ErrorBoundary>
                  <NotesView />
                </ErrorBoundary>
              }
            />
            <Route
              path="/"
              element={
                <ErrorBoundary>
                  <AllNotesView />
                </ErrorBoundary>
              }
            />
          </>
        )}

      {isUserHavePermissions(currentUser!, [
        PermissionsList.viewAnyNote,
        PermissionsList.viewOwnNote,
        PermissionsList.viewMyServiceNote,
      ]) && (
        <Route
          path="/:noteId"
          element={
            <ErrorBoundary>
              <NoteView />
            </ErrorBoundary>
          }
        />
      )}

      {isUserHavePermissions(currentUser!, [
        PermissionsList.editAnyNote,
        PermissionsList.editOwnNote,
      ]) && (
        <Route
          path="/:noteId/edit"
          element={
            <ErrorBoundary>
              <NoteEdit />
            </ErrorBoundary>
          }
        />
      )}

      {isUserHavePermissions(currentUser!, [PermissionsList.createNote]) && (
        <>
          <Route
            path="/create"
            element={
              <ErrorBoundary>
                <NoteCreate />
              </ErrorBoundary>
            }
          />
          <Route
            path="/create/locations/:locationId"
            element={
              <ErrorBoundary>
                <NoteCreate />
              </ErrorBoundary>
            }
          />
          <Route
            path="/create/tasks/:taskId"
            element={
              <ErrorBoundary>
                <NoteCreate />
              </ErrorBoundary>
            }
          />
          <Route
            path="/create/visits/:visitId"
            element={
              <ErrorBoundary>
                <NoteCreate />
              </ErrorBoundary>
            }
          />
        </>
      )}

      <Route path={`*`} element={<PageNotFound />} />
    </Routes>
  );
}

export default NotesRouter;
