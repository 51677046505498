import "react-datepicker/dist/react-datepicker.css";

import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import WorkingHours from "components/Shared/Profile/WorkingHours/WorkingHours";
import UpTimePicker from "components/Shared/ScrollablePicker/ScrollablePicker";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { DateFormI } from "shared/Enums";
import { checkDateValidation } from "shared/helpers/common.helper";
import { formatTimeStringToDate } from "shared/helpers/profile.helper";
import { formatStringToTimeFormat, TimeFormatToString } from "shared/helpers/time.helper";
import { WorkingBreakMocked } from "shared/MockedData/Profile.mocked";
import { TimeOptionGroups } from "shared/MockedData/TimePicker.mocked";
import { editSpecialWorkingHours, getSpecialWorkingHours } from "shared/Services";
import * as Yup from "yup";

import { SpecialDaysDto, TimeFormatI, WorkingHoursItemI } from "@interfaces";
import { Switch, TextField } from "@mui/material";

import styles from "./SpecialDaysCreate.module.scss";
import { toast } from "react-toastify";

const moment = require("moment-timezone");
function SpecialDaysCreate() {
  const { currentUser } = useContext(UserDataContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [workingHoursObj, setWorkingHoursObj] = useState<{
    id: string;
    days: SpecialDaysDto[];
  } | null>(null);

  const [timeValue, setTimeValue] = useState<{
    from: TimeFormatI;
    to: TimeFormatI;
    selected: DateFormI;
  }>({
    from: formatStringToTimeFormat("09:00 AM"),
    to: formatStringToTimeFormat("06:00 PM"),
    selected: DateFormI.from,
  });

  const [vacation, setVacation] = useState(false);
  const [breaks, setBreaks] = useState<WorkingHoursItemI[]>([]);

  const navigate = useNavigate();

  const onDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
    } as {
      title: string;
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (breaks[0].checked && !checkDateValidation(breaks[0].from, breaks[0].to)) {
        toast.error("Break end time can't be earlier than start time");
      } else if (
        !checkDateValidation(
          TimeFormatToString(timeValue.from),
          TimeFormatToString(timeValue.to)
        )
      ) {
        toast.error("Working hours End time can't be earlier than start time");
      } else {
        editSpecialWorkingHours(workingHoursObj!.id, [
          ...(workingHoursObj?.days || []),
          {
            break_hours_start_time:
              !vacation && breaks[0].checked
                ? formatTimeStringToDate(breaks[0].from, currentUser!.timezone.value)
                : null,
            break_hours_end_time:
              !vacation && breaks[0].checked
                ? formatTimeStringToDate(breaks[0].to, currentUser!.timezone.value)
                : null,
            end_date: endDate
              ? moment.utc(endDate).tz(currentUser!.timezone.value).format("YYYY-MM-DD")
              : moment
                  .utc(startDate)
                  .tz(currentUser!.timezone.value)
                  .format("YYYY-MM-DD"),
            start_date: moment
              .utc(startDate)
              .tz(currentUser!.timezone.value)
              .format("YYYY-MM-DD"),
            title: values.title,
            working_hours_start_time: !vacation
              ? formatTimeStringToDate(
                  TimeFormatToString(timeValue.from),
                  currentUser!.timezone.value
                )
              : null,
            working_hours_end_time: !vacation
              ? formatTimeStringToDate(
                  TimeFormatToString(timeValue.to),
                  currentUser!.timezone.value
                )
              : null,
          },
        ]).then((data: AxiosError | any) => {
          if (axios.isAxiosError(data)) {
            toast.error(
              data.response?.data?.errors?.length
                ? data.response?.data?.errors[0]?.detail
                : ""
            );
          } else {
            toast.success("Submitted!");
            navigate("/profile");
          }
        });
      }
    },
  });

  useEffect(() => {
    getSpecialWorkingHours(currentUser!.uid).then(
      (data: AxiosError<{ errors: any }> | { id: string; days: SpecialDaysDto[] }) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
          navigate("/profile");
        } else {
          setWorkingHoursObj(data);
        }
      }
    );
  }, []);

  return (
    <>
      <FormsHeader
        title="Add Special days"
        rightBtnText="Save"
        onRightBtnToggle={() => formik.handleSubmit()}
        position={"fixed"}
        onCancelToggle={() => navigate("/profile")}
      />

      <div className={styles["up-container"]}>
        <TextField
          classes={{ root: styles["up-title"] }}
          placeholder="Name this day"
          variant="outlined"
          hiddenLabel={true}
          fullWidth={true}
          multiline
          InputProps={{
            classes: { root: styles["up-title-input"] },
          }}
          id="title"
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
        <div className={styles["up-form"]}>
          <div className={styles["up-form-block"]}>
            <div className={styles["up-form-date-picker"]}>
              <div className="range-calendar">
                <DatePicker
                  className=""
                  selected={null}
                  onChange={onDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                  minDate={new Date()}
                />
              </div>
            </div>
            <div className={styles["up-form-checkbox-block"]}>
              <label className={styles["up-form-title"]}>
                Set Working Hours for selected days
              </label>
              <div className={styles["up-form-checkbox-row"]}>
                <span className={styles["up-form-checkbox-title"]}>
                  Set as a vacation
                </span>

                <Switch
                  color="default"
                  onChange={(e) => setVacation(e.target.checked)}
                  classes={{
                    root: styles["up-switch"],
                    thumb: styles["up-switch-thumb"],
                    track: styles["up-switch-track"],
                    switchBase: styles["up-switch-switch-base"],
                    checked: styles["up-switch-checked"],
                  }}
                />
              </div>
            </div>
            {!vacation && (
              <>
                <div className={styles["up-form-time-block"]}>
                  <div className={styles["up-form-time-tabs"]}>
                    <div className={styles["up-form-time-tabs-block"]}>
                      <label className={styles["up-form-time-tabs-label"]}>Start</label>

                      <div
                        onClick={() => {
                          setTimeValue({
                            ...timeValue,
                            selected: DateFormI.from,
                          });
                        }}
                        className={`${styles["up-form-time-tabs-field"]} ${
                          timeValue.selected === DateFormI.from ? styles["active"] : ""
                        }`}
                      >
                        {TimeFormatToString(timeValue.from)}
                      </div>
                    </div>
                    <span className={styles["up-form-time-tabs-divider"]}>-</span>
                    <div className={styles["up-form-time-tabs-block"]}>
                      <label className={styles["up-form-time-tabs-label"]}>End</label>

                      <div
                        onClick={() => {
                          setTimeValue({
                            ...timeValue,
                            selected: DateFormI.to,
                          });
                        }}
                        className={`${styles["up-form-time-tabs-field"]} ${
                          timeValue.selected === DateFormI.to ? styles["active"] : ""
                        }`}
                      >
                        {TimeFormatToString(timeValue.to)}
                      </div>
                    </div>
                  </div>

                  <UpTimePicker
                    optionGroups={TimeOptionGroups}
                    valueGroups={
                      timeValue[timeValue.selected] as unknown as {
                        [key: string]: string;
                      }
                    }
                    onChange={(name: string, value: string) =>
                      setTimeValue({
                        ...timeValue,
                        [timeValue.selected]: {
                          ...timeValue[timeValue.selected],
                          [name]: value,
                        },
                      })
                    }
                  />
                </div>

                <div className={styles["up-form-working-hours"]}>
                  <WorkingHours hoursChanged={setBreaks} hours={WorkingBreakMocked} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SpecialDaysCreate;
