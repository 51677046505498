import Header from "components/Shared/Header/Header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppType } from "shared/Enums";

function PageNotFound() {
  const navigate = useNavigate();

  // Redirect to the home page if the user is using Volt and is on the index.html page
  // TODO - check if it's possible to remove this check
  useEffect(() => {
    if (
      process.env.REACT_APP_TYPE === AppType.app &&
      window.location.pathname === "/index.html"
    ) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Header title={""} toggleBack={() => navigate("/")} hideReload={true} />
      <div style={{ paddingTop: "var(--size-65px)", textAlign: "center" }}>
        Page not found
      </div>
    </>
  );
}

export default PageNotFound;
