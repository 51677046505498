import { ProductsI, ResponseDto } from "@interfaces";

import {
  apiGetCountriesAndStates,
  apiGetProductsList,
  apiGetSettingsList,
  apiGetUsersList,
} from "./Api/Common.api.service";

export const getCountriesAndStates = () => {
  return apiGetCountriesAndStates().catch((err) => err);
};

export const getProductsList = (page: number) => {
  return apiGetProductsList(page)
    .then((data) => {
      return {
        hasMore: !(data.data.data?.length < 5),
        content:
          data.data.data?.map((i: { id: any; attributes: { label: string } }) => {
            return {
              id: i.id,
              title: i.attributes.label,
            };
          }) || ([] as ProductsI[]),
      };
    })
    .catch((err) => err);
};

export const getSettingsList = () => {
  return apiGetSettingsList()
    .then((i) => {
      return {
        ...i.data,
        priority: Object.values(i.data.priority),
      };
    })
    .catch((err) => err);
};
export const getUsersList = (page: number, userId: string) => {
  return apiGetUsersList(page, userId)
    .then((data) => {
      const userList = data.data.included?.filter(
        (i: ResponseDto<any>) => i.type === "user--user"
      );
      return {
        hasMore: !(data.data.data?.length < 5),
        content: data.data.data?.map((i: any) => {
          const user = userList.find(
            (u: any) => i.id === u.relationships.agent_profiles.data.id
          );
          return {
            id: user.id,
            title: user.attributes.field_full_name,
          };
        }),
      };
    })
    .catch((err) => err);
};
