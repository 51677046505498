import axios from "axios";
import moment, { Moment } from "moment-timezone";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import VisitCard from "components/Shared/VisitCard/VisitCard";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ManagerContext, UserDataContext } from "shared/Contexts";
import { getAppointmentsVisitsList } from "shared/Services";

import { Global } from "@emotion/react";
import { VisitCardsNewI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import { Button, SwipeableDrawer, TextField } from "@mui/material";
import { PickersDay, StaticDatePicker } from "@mui/x-date-pickers-pro";

import styles from "./ViewCalendar.module.scss";
import { toast } from "react-toastify";
import AgentProfileNotExist from "components/Shared/AgentProfileNotExist/AgentProfileNotExist";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { PermissionsList } from "shared/Enums";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import { getAppointmentsAndVisitsByMonth } from "shared/Services/Appointments.service";

function ViewCalendar() {
  const navigate = useNavigate();

  const { currentUser } = useContext(UserDataContext);
  const [{ agent }] = useContext(ManagerContext);

  const [toggle, setToggle] = useState(false);
  const [activeDays, setActiveDays] = useState<Set<string>>(new Set());
  const [date, setDate] = useState(moment().tz(currentUser!.timezone.value));
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState<VisitCardsNewI[]>([]);
  let touchPosition = 0;

  useEffect(() => {
    getContent();
    getActiveDaysByMonth(date);
  }, [date]);

  const getActiveDaysByMonth = (d: Moment) => {
    setIsLoading(true);
    getAppointmentsAndVisitsByMonth(d, agent ? agent.uuid : currentUser!.uid).then(
      (resp) => {
        if (axios.isAxiosError(resp)) {
          toast.error(resp.response?.data?.errors[0]?.detail);
        } else {
          const updatedActiveDays = new Set<string>();
          resp.forEach((i: any) => {
            updatedActiveDays.add(i.date);
          });
          setActiveDays(updatedActiveDays);
          setIsLoading(false);
        }
      }
    );
  };

  const renderPickerDay = (date: any, _: any, pickersDayProps: any) => {
    let selected = false;
    if (activeDays.has(date.format("YYYY-MM-DD"))) {
      selected = true;
    }
    return (
      <PickersDay
        {...pickersDayProps}
        classes={{ root: selected && styles["active-day"] }}
      />
    );
  };

  const getContent = () => {
    setIsLoading(true);
    getAppointmentsVisitsList(date, agent ? agent.uuid : currentUser!.uid).then(
      (resp) => {
        if (axios.isAxiosError(resp)) {
          toast.error(resp.response?.data?.errors[0]?.detail);
        } else {
          setContent(resp);
          setIsLoading(false);
        }
      }
    );
  };

  const formatDate = (date: moment.Moment) => {
    return moment(date).format("ll");
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchPosition = e.changedTouches[0].clientY;
  };

  const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!toggle && touchPosition > e.changedTouches[0].clientY - 50) {
      setToggle(true);
    }
  };

  const headerTitle = (
    <div className={agent ? styles["up-header--agent-mode"] : ""}>
      {agent ? (
        <>
          <div>Calendar of</div>
          <div>{agent.fullName}</div>
        </>
      ) : (
        "Calendar"
      )}
    </div>
  );

  return (
    <div className={styles["up-container"]}>
      <Header
        title={headerTitle}
        toggleBack={() => navigate("/")}
        toggleReload={() => getContent()}
      />
      {currentUser?.agentProfiles || agent ? (
        <>
          <Global
            styles={{
              ".MuiModal-hidden .up-drawer-content": {
                height: "calc(100vh - 31.25rem)",
              },
            }}
          />
          <StaticDatePicker
            className={"mat-calendar"}
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={date}
            renderDay={renderPickerDay}
            onChange={(newValue) => newValue && setDate(newValue)}
            onMonthChange={(m: Moment) => {
              getActiveDaysByMonth(m);
            }}
            renderInput={(params) => <TextField {...params} />}
          />

          <SwipeableDrawer
            open={toggle}
            onClose={() => setToggle(false)}
            onOpen={() => setToggle(true)}
            anchor="bottom"
            classes={{
              paper: styles["up-drawer-paper"],
              root: styles["up-drawer-root"],
            }}
            ModalProps={{
              keepMounted: true,
            }}
            disableSwipeToOpen={true}
          >
            <div className={styles["up-drawer-block"]}>
              <div className={styles["up-drawer-header"]}>
                <div className={styles["up-puller"]} />
                <div
                  className={styles["up-puller-btn"]}
                  onTouchStart={(mouseDownEvent) => onTouchStart(mouseDownEvent)}
                  onTouchEnd={(e) => onTouchEnd(e)}
                  onClick={() => setToggle(true)}
                />
                <div className={styles.date}>{formatDate(date)}</div>

                {isUserHavePermissions(currentUser as UserDataContextI, [
                  PermissionsList.createAppointment,
                ]) &&
                  date.isSameOrAfter(undefined, "day") && (
                    <Button
                      classes={{ root: styles["appointment-button"] }}
                      onClick={() =>
                        navigate("/visits/create", {
                          state: { date: date.format() },
                        })
                      }
                      variant="outlined"
                      startIcon={<AddIcon color="inherit" />}
                    >
                      Create appointment
                    </Button>
                  )}
              </div>
              <div className={`${styles["up-drawer-content"]} up-drawer-content`}>
                <LoadingSpinner isLoading={isLoading}>
                  {content.length ? (
                    content.map((item) => (
                      <div key={item.id} className={styles["up-card"]}>
                        <VisitCard
                          item={item}
                          onActionToggle={() => {}}
                          editableAppointment={true}
                        ></VisitCard>
                      </div>
                    ))
                  ) : (
                    <div className="up-no-items">
                      No completed visits and appointments found this day.
                    </div>
                  )}
                </LoadingSpinner>
              </div>
            </div>
          </SwipeableDrawer>
        </>
      ) : (
        <AgentProfileNotExist />
      )}
    </div>
  );
}

export default ViewCalendar;
