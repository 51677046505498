import UpDialog from "components/Shared/Dialog/Dialog";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CountriesStatesContext, RouterContext, UserDataContext } from "shared/Contexts";
import {
  DayScheduleActionsNew,
  DayScheduleStepsTypeNew,
  DayScheduleTypeNew,
  PermissionsList,
} from "shared/Enums";
import {
  formatAddressToString,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import { check12AMto00 } from "shared/helpers/time.helper";

import { DayScheduleDayNewI, VisitBreaksNewI } from "@interfaces";
import { Button, Card, CardActions, CardContent } from "@mui/material";

import styles from "./DayScheduleBreak.module.scss";
import { toast } from "react-toastify";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import { openMapApp } from "shared/helpers/openMapApp";

const moment = require("moment-timezone");

function DayScheduleBreak({
  item,
  day,
  onActionToggle,
}: {
  item: VisitBreaksNewI;
  day?: DayScheduleDayNewI;
  onActionToggle: (action: DayScheduleActionsNew) => void;
}) {
  const { countriesStates } = useContext(CountriesStatesContext);
  const [, setRouterValue] = useContext(RouterContext);
  const { currentUser } = useContext(UserDataContext);

  const navigate = useNavigate();

  const questions = {
    [DayScheduleActionsNew.startDay]: "Are you sure you want to start your day?",
    [DayScheduleActionsNew.endDay]: "Are you sure you want to finish your day?",
    [DayScheduleActionsNew.skipVisit]: "",
    [DayScheduleActionsNew.unskipVisit]: "",
    [DayScheduleActionsNew.completeVisit]: "",
    [DayScheduleActionsNew.createVisit]: "",
  };
  const [popupState, setPopupState] = useState<[boolean, DayScheduleActionsNew]>([
    false,
    DayScheduleActionsNew.startDay,
  ]);

  const onPopupClose = (confirm: boolean) => {
    confirm && onActionToggle(popupState[1]);
    setPopupState([false, popupState[1]]);
  };

  return (
    <Card
      classes={{
        root: styles["up-route-start"],
      }}
    >
      <CardContent classes={{ root: styles["up-route-start-content"] }}>
        {(() => {
          switch (item.cardType) {
            case DayScheduleStepsTypeNew.start:
              return (
                <>
                  <span>
                    Route start is scheduled for <strong>{item.time}</strong>
                  </span>
                  {item.address && (
                    <span>
                      from{" "}
                      <strong>
                        {formatAddressToString(item.address, countriesStates)}
                      </strong>
                    </span>
                  )}
                </>
              );
            case DayScheduleStepsTypeNew.end:
              return (
                <>
                  <span>
                    Route end is scheduled for <strong>{item.time}</strong>
                  </span>
                  {item.address && (
                    <span>
                      at{" "}
                      <strong>
                        {formatAddressToString(item.address, countriesStates)}
                      </strong>
                    </span>
                  )}
                </>
              );
            case DayScheduleStepsTypeNew.break:
              return (
                <>
                  <span>
                    Brake is scheduled for{" "}
                    <strong>
                      {check12AMto00(item.time, currentUser!.timezone.value)}
                    </strong>
                    {item.timeTo && ` - ${item.timeTo}`}
                  </span>
                </>
              );
          }
        })()}
      </CardContent>
      {item.cardType !== DayScheduleStepsTypeNew.break && (
        <CardActions classes={{ root: styles["up-route-start-actions"] }} disableSpacing>
          {isUserHavePermissions(currentUser as UserDataContextI, [
            PermissionsList.viewOwnDay,
          ]) &&
            day?.status === DayScheduleTypeNew.pending &&
            moment(day?.date).isSame(
              moment().utc().tz(currentUser!.timezone.value),
              "day"
            ) &&
            item.cardType === DayScheduleStepsTypeNew.start && (
              <Button
                variant="outlined"
                classes={{ root: styles["up-route-start-button"] }}
                onClick={() => navigate("visits/location/start")}
              >
                Edit
              </Button>
            )}

          {isUserHavePermissions(currentUser as UserDataContextI, [
            PermissionsList.viewOwnDay,
          ]) &&
            day?.status === DayScheduleTypeNew.pending &&
            moment(day?.date).isSame(
              moment().utc().tz(currentUser!.timezone.value),
              "day"
            ) &&
            item.cardType === DayScheduleStepsTypeNew.end && (
              <Button
                variant="outlined"
                classes={{ root: styles["up-route-start-button"] }}
                onClick={() => navigate("visits/location/end")}
              >
                Edit
              </Button>
            )}
          <Button
            variant="outlined"
            onClick={() =>
              openMapApp(
                {
                  lat: item.geolocation.lat,
                  lng: item.geolocation.lng,
                },
                (e) => toast.error(e)
              )
            }
            classes={{
              root: styles["up-route-start-button"],
            }}
          >
            Direction
          </Button>
          <Button
            variant="outlined"
            classes={{
              root: styles["up-route-start-button"],
            }}
            onClick={() =>
              setRouterValue((prev) => ({
                ...prev,
                gMapOpened: true,
                gMapGeolocation: item.geolocation,
                gMapLocationName: item.address
                  ? formatAddressToString(item.address, countriesStates)
                  : "",
              }))
            }
          >
            Map
          </Button>
          {isUserHavePermissions(currentUser as UserDataContextI, [
            PermissionsList.viewOwnDay,
          ]) &&
            day?.status === DayScheduleTypeNew.pending &&
            moment(day?.date).isSame(
              moment().utc().tz(currentUser!.timezone.value),
              "day"
            ) &&
            item.cardType === DayScheduleStepsTypeNew.start && (
              <Button
                variant="outlined"
                classes={{
                  root: styles["up-route-start-button-right"],
                }}
                onClick={() => setPopupState([true, DayScheduleActionsNew.startDay])}
              >
                Start your day
              </Button>
            )}
          {isUserHavePermissions(currentUser as UserDataContextI, [
            PermissionsList.viewOwnDay,
          ]) &&
            day?.status === DayScheduleTypeNew.started &&
            item.cardType === DayScheduleStepsTypeNew.end &&
            moment(day?.date).isSame(
              moment().utc().tz(currentUser!.timezone.value),
              "day"
            ) && (
              <Button
                variant="outlined"
                classes={{
                  root: styles["up-route-start-button-right"],
                }}
                onClick={() => setPopupState([true, DayScheduleActionsNew.endDay])}
              >
                End your day
              </Button>
            )}
        </CardActions>
      )}
      <UpDialog
        question={questions[popupState[1]]}
        onToggle={onPopupClose}
        opened={popupState[0]}
      ></UpDialog>
    </Card>
  );
}

export default DayScheduleBreak;
