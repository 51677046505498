import axios from "axios";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import UpAvatar from "components/Shared/Profile/Avatar/Avatar";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CountriesStatesContext, UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import {
  checkPermissionsForEdit,
  formatAddressToString,
} from "shared/helpers/common.helper";
import { getContactData } from "shared/Services";

import { ContactViewI } from "@interfaces";
import { Button } from "@mui/material";

import ProfileIcon from "../../../../assets/icons/profile.svg";
import TelegramIcon from "../../../../assets/icons/socials/telegram.svg";
import WhatsAppIcon from "../../../../assets/icons/socials/whatsapp.svg";
import styles from "./ContactView.module.scss";
import { toast } from "react-toastify";

const renderIcon = (param: string) => {
  if (param.includes("https://wa.me/")) {
    return <img alt="WhatsApp" src={WhatsAppIcon} />;
  }
  if (param.includes("https://t.me/")) {
    return <img alt="Telegram" src={TelegramIcon} />;
  }
  if (param.includes("https://m.me/")) {
    return <img alt="Telegram" src={TelegramIcon} />;
  }
};

function ContactView() {
  const { currentUser } = useContext(UserDataContext);
  const { countriesStates } = useContext(CountriesStatesContext);
  const [contactContent, setContactContent] = useState<ContactViewI | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const getContent = () => {
    params.contactId &&
      getContactData(params.contactId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          setContactContent(data);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getContent();
  }, []);

  return (
    <>
      <Header
        title={"Contact"}
        toggleBack={() => navigate(-1)}
        opacity={0}
        toggleReload={getContent}
      />
      <LoadingSpinner isLoading={isLoading} height={300}>
        {contactContent ? (
          <div className={styles["up-container"]}>
            <div className={styles["up-card"]}>
              <div className={styles["up-card-edit"]}>
                {checkPermissionsForEdit(
                  currentUser!,
                  PermissionsList.editAnyContact,
                  PermissionsList.editOwnContact,
                  contactContent.authorId
                ) && (
                  <Button
                    classes={{ root: styles["up-card-edit-button"] }}
                    color={"inherit"}
                    onClick={() => navigate("edit")}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <div className={styles["up-card-avatar"]}>
                <UpAvatar
                  name={contactContent.name}
                  avatar={contactContent?.avatar?.url || null}
                  defaultImage={ProfileIcon}
                />
              </div>
              <div className={styles["up-card-name"]}>{contactContent.name}</div>
              {contactContent.position && (
                <div className={styles["up-card-position"]}>
                  {contactContent.position}
                </div>
              )}
            </div>

            <div className={styles["up-content"]}>
              {contactContent.phoneNumbers?.length ? (
                <>
                  <label className={styles["up-content-label"]}>Phone number</label>
                  <div className={styles["up-content-value"]}>
                    {contactContent.phoneNumbers.map((p, index) => (
                      <a key={index} rel="noreferrer" target="_blank" href={`tel:${p}`}>
                        {p}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}

              {contactContent.emails?.length ? (
                <>
                  <label className={styles["up-content-label"]}>Email</label>
                  <div className={styles["up-content-value"]}>
                    {contactContent.emails.map((p, index) => (
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={`mailto:${p}`}
                      >
                        {p}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}
              {contactContent.address && (
                <>
                  <label className={styles["up-content-label"]}>Address</label>
                  <div className={styles["up-content-value"]}>
                    {formatAddressToString(contactContent.address, countriesStates)}
                  </div>
                </>
              )}

              {contactContent.location && (
                <>
                  <label className={styles["up-content-label"]}>Location</label>
                  <div className={styles["up-content-value"]}>
                    <span
                      onClick={() =>
                        navigate(`/locations/${contactContent.location?.id}`)
                      }
                    >
                      {contactContent.location.name}
                    </span>
                  </div>
                </>
              )}

              <label className={styles["up-content-label"]}>Company</label>
              <div className={styles["up-content-value"]}>
                <span onClick={() => navigate(`/companies/${contactContent.company.id}`)}>
                  {contactContent.company.name}
                </span>
              </div>

              {contactContent.links.length ? (
                <>
                  <label className={styles["up-content-label"]}>Links</label>
                  <div className={styles["up-content-value"]}>
                    {contactContent.links.map((l, index) => (
                      <a key={index} rel="noreferrer" target="_blank" href={l}>
                        {l}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}

              {contactContent.messengers?.length ? (
                <>
                  <label className={styles["up-content-label"]}>Contact via</label>
                  <div className={styles["up-content-icons"]}>
                    {contactContent.messengers?.map((l, index) => (
                      <a key={index} rel="noreferrer" target="_blank" href={l}>
                        {renderIcon(l)}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <></>
        )}
      </LoadingSpinner>
    </>
  );
}

export default ContactView;
