import { AxiosResponse } from "axios";

import { helperApi, jsonApiFile, jsonAuthApi } from "./Settings.api.service";

export const apiGetUserProfile = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/user/user/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
    params: {
      include: "agent_profiles,field_avatar",
    },
  });
};

export const apiGetUserName = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/user/user/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiEditProfileData = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/user/user/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiEditProfileAgent = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/profile/agent/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiCreateProfileAgent = (body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.post(`/profile/agent`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiAddAvatar = (id: string, body: any): Promise<AxiosResponse<any, any>> => {
  return jsonApiFile.post(`/user/user/${id}/field_avatar`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetAgentProfile = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/user/user/${id}`, {
    params: {
      include: "agent_profiles",
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiChangePassword = (body: any): Promise<AxiosResponse<any, any>> => {
  return helperApi.post(`/user/password/change`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
