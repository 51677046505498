import Header from "components/Shared/Header/Header";
import { Component, PropsWithChildren } from "react";

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error: any) {
    this.setState({ hasError: true, error });
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <Header
            title={""}
            toggleBack={() => (window.location.href = "/")}
            hideReload={true}
          />
          <div style={{ paddingTop: "var(--size-65px)", textAlign: "center" }}>
            Sorry, something went wrong
          </div>
        </>
      );
    }
    return this.props.children;
  }
}
