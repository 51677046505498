import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { CheckRoutingPermission } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import EditMyProfile from "./EditMyProfile/EditMyProfile";
import Settings from "./Settings/Settings";
import SpecialDaysCreate from "./SpecialDaysCreate/SpecialDaysCreate";
import TimeReservation from "./TimeReservation/TimeReservation";
import ViewMyProfile from "./ViewMyProfile/ViewMyProfile";
import { ErrorBoundary } from "error";

function ProfileRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <ViewMyProfile />
          </ErrorBoundary>
        }
      />
      <Route
        path="/edit"
        element={
          <ErrorBoundary>
            <EditMyProfile />
          </ErrorBoundary>
        }
      />
      {CheckRoutingPermission(currentUser!) && (
        <>
          <Route
            path="/special-days/create"
            element={
              <ErrorBoundary>
                <SpecialDaysCreate />
              </ErrorBoundary>
            }
          />
          <Route
            path="/time-reservation"
            element={
              <ErrorBoundary>
                <TimeReservation />
              </ErrorBoundary>
            }
          />
        </>
      )}
      <Route
        path="/settings"
        element={
          <ErrorBoundary>
            <Settings />
          </ErrorBoundary>
        }
      />
      <Route path={`*`} element={<PageNotFound />} />
    </Routes>
  );
}

export default ProfileRouter;
