import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import styles from "./DayScheduleCalendar.module.scss";
import DayScheduleBreak from "../DayScheduleBreak/DayScheduleBreak";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import UpTabs from "components/Shared/Tabs/Tabs";
import VisitCard from "components/Shared/VisitCard/VisitCard";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ManagerContext, RouterContext, UserDataContext } from "shared/Contexts";
import {
  DayScheduleActionsNew,
  DayScheduleStepsStatusesNew,
  DayScheduleStepsTabsTypeNew,
  DayScheduleStepsTypeNew,
  DayScheduleTypeNew,
  TabsStatuses,
} from "shared/Enums";
import { WeekShortDays } from "shared/helpers/profile.helper";
import {
  CreateVisit,
  editDayStatus,
  editStepStatus,
  editVisitStatus,
  DeleteVisit,
  GetLastDayByDate,
  getRouting,
  getAgentRouting,
} from "shared/Services";

import { DaySchedulePageNewI, VisitBreaksNewI, VisitCardsNewI } from "@interfaces";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TodayIcon from "@mui/icons-material/Today";
import { Button } from "@mui/material";

function DayScheduleCalendar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [routerValue, setRouterValue] = useContext(RouterContext);
  const { currentUser } = useContext(UserDataContext);
  const [{ agent }] = useContext(ManagerContext);

  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState(
    location.state?.date
      ? moment(location.state.date).tz(currentUser!.timezone.value)
      : moment().tz(currentUser!.timezone.value)
  );
  const [selectedTab, setSelectedTab] = useState<DayScheduleStepsTabsTypeNew>(
    DayScheduleStepsTabsTypeNew.activeSteps
  );

  const [content, setContent] = useState<DaySchedulePageNewI>();

  const changeTab = (type: TabsStatuses) => {
    switch (type) {
      case TabsStatuses.active:
        setSelectedTab(DayScheduleStepsTabsTypeNew.activeSteps);
        return;
      case TabsStatuses.skipped:
        setSelectedTab(DayScheduleStepsTabsTypeNew.skippedSteps);
        return;
      case TabsStatuses.completed:
        setSelectedTab(DayScheduleStepsTabsTypeNew.completedSteps);
        return;
    }
  };

  const changeDay = (val: number) => {
    setIsLoading(true);
    const newDate = date.add(val, "days");
    setDate(newDate.clone());

    navigate("/", {
      state: {
        date: newDate.format(),
      },
    });
  };

  function isCard(object: VisitCardsNewI | VisitBreaksNewI): object is VisitCardsNewI {
    return object.cardType === DayScheduleStepsTypeNew.service;
  }

  const ActionToggle = (
    action: DayScheduleActionsNew,
    id?: string,
    locationId?: string
  ) => {
    setIsLoading(true);
    switch (action) {
      case DayScheduleActionsNew.startDay:
        editDayStatus(content!.day.id, action, currentUser).then((data) => {
          if (axios.isAxiosError(data)) {
            toast.error(
              data.response?.data?.errors?.length
                ? data.response?.data?.errors[0]?.detail
                : ""
            );
          } else {
            setContent({
              ...content!,
              day: {
                ...content!.day,
                status: DayScheduleTypeNew.started,
              },
            });
            setIsLoading(false);
          }
        });
        break;
      case DayScheduleActionsNew.endDay:
        editDayStatus(content!.day.id, action, currentUser).then((data) => {
          if (axios.isAxiosError(data)) {
            toast.error(
              data.response?.data?.errors?.length
                ? data.response?.data?.errors[0]?.detail
                : ""
            );
          } else {
            setContent({
              ...content!,
              day: {
                ...content!.day,
                status: DayScheduleTypeNew.ended,
              },
            });
          }
        });
        break;
      case DayScheduleActionsNew.skipVisit:
        // eslint-disable-next-line no-case-declarations
        const active =
          (content!.steps.activeSteps?.filter(
            (i) => isCard(i) && i.id === id
          ) as VisitCardsNewI[]) || [];

        // eslint-disable-next-line no-case-declarations
        const foundActiveVisitId = [...active].find((i) => i.visitId)?.visitId;
        if (foundActiveVisitId) {
          editVisitStatus(foundActiveVisitId, action, currentUser!.timezone.value).then(
            (data) => {
              if (axios.isAxiosError(data)) {
                toast.error(
                  data.response?.data?.errors?.length
                    ? data.response?.data?.errors[0]?.detail
                    : ""
                );
              }
              editStepStatus(id!, action).then((data) => {
                if (axios.isAxiosError(data)) {
                  toast.error(
                    data.response?.data?.errors?.length
                      ? data.response?.data?.errors[0]?.detail
                      : ""
                  );
                } else {
                  Calculate();
                }
              });
            }
          );
        } else {
          CreateVisit(
            currentUser!.timezone.value,
            locationId!,
            content!.day!.id,
            "skipped"
          ).then((data) => {
            if (axios.isAxiosError(data)) {
              toast.error(
                data.response?.data?.errors?.length
                  ? data.response?.data?.errors[0]?.detail
                  : ""
              );
            }
            editStepStatus(id!, action).then((data) => {
              if (axios.isAxiosError(data)) {
                toast.error(
                  data.response?.data?.errors?.length
                    ? data.response?.data?.errors[0]?.detail
                    : ""
                );
              } else {
                Calculate();
              }
            });
          });
        }
        break;
      case DayScheduleActionsNew.unskipVisit:
        // eslint-disable-next-line no-case-declarations
        const skipped =
          (content!.steps.skippedSteps?.filter(
            (i) => isCard(i) && i.id === id
          ) as VisitCardsNewI[]) || [];

        // eslint-disable-next-line no-case-declarations
        const foundVisitId = [...skipped].find((i) => i.visitId)?.visitId;
        if (foundVisitId) {
          DeleteVisit(foundVisitId).then((data) => {
            if (axios.isAxiosError(data)) {
              toast.error(
                data.response?.data?.errors?.length
                  ? data.response?.data?.errors[0]?.detail
                  : ""
              );
            }
            editStepStatus(id!, action).then((data) => {
              if (axios.isAxiosError(data)) {
                toast.error(
                  data.response?.data?.errors?.length
                    ? data.response?.data?.errors[0]?.detail
                    : ""
                );
              } else {
                Calculate();
              }
            });
          });
        } else {
          editStepStatus(id!, action).then((data) => {
            if (axios.isAxiosError(data)) {
              toast.error(
                data.response?.data?.errors?.length
                  ? data.response?.data?.errors[0]?.detail
                  : ""
              );
            } else {
              Calculate();
            }
          });
        }
        break;
      case DayScheduleActionsNew.createVisit:
        if (content!.steps.activeSteps.find((i) => isCard(i) && i.visitId)) {
          toast.info(
            "You already have an unfinished visit. Please complete it before starting a new one."
          );
        } else {
          CreateVisit(
            currentUser!.timezone.value,
            locationId!,
            content!.day!.id,
            DayScheduleStepsStatusesNew.inProgress
          ).then((data) => {
            if (axios.isAxiosError(data)) {
              toast.error(
                data.response?.data?.errors?.length
                  ? data.response?.data?.errors[0]?.detail
                  : ""
              );
            } else {
              navigate("/visits/" + data);
            }
          });
        }
        break;
    }
  };

  const Calculate = () => {
    if (!date.isBefore(moment().utc().tz(currentUser!.timezone.value), "day")) {
      getRouting(date, routerValue.myLocation!).then((resp) => {
        if (axios.isAxiosError(resp)) {
          setIsLoading(false);
          toast.error(
            resp.response?.data?.errors?.length
              ? resp.response?.data?.errors[0]?.detail
              : resp.response?.data?.message ||
                  `see DayScheduleCalendar.tsx line 236. resp: ${JSON.stringify(resp)}`
          );
          return;
        } else if (resp.error) {
          setIsLoading(false);
          toast.error(typeof resp.error === "string" ? resp.error : resp.error[0]);
          setContent(undefined);
          return;
        }
        setContent(resp);
      });
    } else {
      GetLastDayByDate(date).then((resp) => {
        if (axios.isAxiosError(resp)) {
          setIsLoading(false);
          toast.error(resp.response?.data?.errors[0]?.detail);
          return;
        }
        setContent(resp);
        changeTab(TabsStatuses.completed);
      });
    }
  };

  const CalculateForManager = () => {
    if (!date.isBefore(moment().utc().tz(currentUser!.timezone.value), "day")) {
      getAgentRouting(date, agent?.uuid as string).then((resp) => {
        if (axios.isAxiosError(resp)) {
          setIsLoading(false);
          toast.error(
            resp.response?.data?.errors?.length
              ? resp.response?.data?.errors[0]?.detail
              : resp.response?.data?.message ||
                  `see DayScheduleCalendar.tsx line 268. resp: ${JSON.stringify(resp)}`
          );
          return;
        } else if (resp.error) {
          setIsLoading(false);
          toast.error(typeof resp.error === "string" ? resp.error : resp.error[0]);
          setContent(undefined);
          return;
        }

        setContent(resp);
      });
    } else {
      GetLastDayByDate(date, agent?.uuid).then((resp) => {
        if (axios.isAxiosError(resp)) {
          setIsLoading(false);
          toast.error(
            resp.response?.data?.errors?.length
              ? resp.response?.data?.errors[0]?.detail
              : ""
          );
          return;
        }
        setContent(resp);
        changeTab(TabsStatuses.completed);
      });
    }
  };

  const isToday = () => {
    const today = moment()
      .tz(currentUser?.timezone.value as string)
      .startOf("day"); // Get the start of today
    return date.isSame(today, "day");
  };

  useEffect(() => {
    agent ? CalculateForManager() : Calculate();
    changeTab(TabsStatuses.active);
  }, [date, agent]);

  useEffect(() => {
    content && setIsLoading(false);
  }, [content]);

  return (
    <>
      <div className={styles["up-header"]}>
        <Button
          classes={{ root: styles["up-button"] }}
          variant="outlined"
          color={"inherit"}
          disabled={isLoading}
          onClick={() => changeDay(-1)}
          aria-label="back"
        >
          <NavigateBeforeIcon fontSize={"large"} />
        </Button>

        <h1 className={styles["up-title"]}>
          <span>{date.format("MMM D, YYYY")} - </span>
          <span className={date.day() === 0 || date.day() === 6 ? styles["weekend"] : ""}>
            {WeekShortDays[date.day()]}
          </span>
          {/* {currentUser?.routerSettings.testMode.isTest && <span>{moment.utc(moment.duration(currentUser?.routerSettings.testMode.time, "seconds").asMilliseconds())
              .format('HH:mm')}
            </span>} */}

          {!isToday() && (
            <span
              onClick={() => {
                setIsLoading(true);
                setDate(moment().tz(currentUser!.timezone.value));
                navigate("/", { state: { date: null } });
              }}
              className={styles["up-title--today-icon"]}
            >
              <TodayIcon />
            </span>
          )}
        </h1>

        <Button
          classes={{ root: styles["up-button"] }}
          variant="outlined"
          color={"inherit"}
          disabled={isLoading}
          onClick={() => changeDay(1)}
          aria-label="next"
        >
          <NavigateNextIcon fontSize={"large"} />
        </Button>
      </div>

      <LoadingSpinner isLoading={isLoading}>
        {content && content.tabs && content.steps[selectedTab] && (
          <UpTabs
            tabs={content.tabs}
            content={content.steps[selectedTab]}
            changeTab={changeTab}
          >
            <>
              {content.steps[selectedTab]?.length ? (
                content.steps[selectedTab]?.map((item, index) => {
                  if (isCard(item)) {
                    return (
                      <div key={index} className={styles["up-card"]}>
                        <VisitCard
                          item={item}
                          day={content.day}
                          onActionToggle={ActionToggle}
                        ></VisitCard>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className={styles["up-card"]}>
                        <DayScheduleBreak
                          onActionToggle={ActionToggle}
                          day={content.day}
                          item={item}
                        />
                      </div>
                    );
                  }
                })
              ) : (
                <div className="up-no-items">No step records found</div>
              )}
            </>
          </UpTabs>
        )}
      </LoadingSpinner>
      {!date.isBefore(moment().utc().tz(currentUser!.timezone.value), "day") && (
        <Button
          onClick={() =>
            setRouterValue((prev) => ({
              ...prev,
              opened: true,
              routeData: content,
            }))
          }
          disabled={isLoading}
          classes={{ root: styles["up-route"] }}
        >
          Route
        </Button>
      )}
    </>
  );
}

export default DayScheduleCalendar;
