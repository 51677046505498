import axios from "axios";
import { TabsStatuses } from "shared/Enums";
import { formatResponseWarning, getTaskStatus } from "shared/helpers/common.helper";

import {
  ResponseDto,
  TabsI,
  TaskCardI,
  TaskCreateFormI,
  TaskDetailsI,
} from "@interfaces";

import { apiCreateNote, apiGetNotesListByTask } from "./Api/Notes.api.service";
import {
  apiCreateTask,
  apiDeleteTask,
  apiEditTaskData,
  apiGetAllActiveTasksList,
  apiGetAllCompletedTasksList,
  apiGetTaskData,
  apiGetTaskName,
} from "./Api/Tasks.api.service";

const moment = require("moment-timezone");

export const getTasksList = (
  type: TabsStatuses,
  page: number,
  timezone: string,
  locationId: string | undefined,
  params: {
    id?: string;
    serviceId?: string;
  }
) => {
  switch (type) {
    case TabsStatuses.completed:
      return apiGetAllCompletedTasksList(20, page, timezone, locationId, params)
        .then((data) => {
          return {
            hasMore: !(data.data.data?.length < 20),
            content:
              data.data.data?.map(
                (i: { id: any; attributes: { description?: { value: string } } }) => {
                  return {
                    id: i.id,
                    title: i.attributes.description?.value || "",
                  };
                }
              ) || ([] as TaskCardI[]),
          };
        })
        .catch((err) => err);
    default:
      return apiGetAllActiveTasksList(20, page, timezone, locationId, params)
        .then((data) => {
          return {
            hasMore: !(data.data.data?.length < 20),
            content:
              data.data.data?.map(
                (i: { id: any; attributes: { description?: { value: string } } }) => {
                  return {
                    id: i.id,
                    title: i.attributes.description?.value || "",
                  };
                }
              ) || ([] as TaskCardI[]),
          };
        })
        .catch((err) => err);
  }
};

export const getTasksNameIdList = (
  pageNumber: number,
  timezone: string,
  params: {
    id?: string;
    serviceId?: string;
  }
) => {
  return apiGetAllCompletedTasksList(10, pageNumber, timezone, undefined, params)
    .then((resp) => {
      const response = formatResponseWarning(resp.data);
      return {
        items:
          response.data?.map((item: any) => {
            return {
              id: item.id,
              title: item.attributes.label,
              locationId: item.relationships.location.data.id,
              status: {
                name: getTaskStatus(item.attributes.completion_date, timezone),
                date: item.attributes.completion_date,
              },
            };
          }) || [],
        hasMore: !(response.data?.length < 20),
      };
    })
    .catch((err) => err);
};

export const getTasksTabsInfo = (
  timezone: string,
  locationId: string | undefined,
  params: {
    id?: string;
    serviceId?: string;
  }
) => {
  return axios
    .all([
      apiGetAllActiveTasksList(1, 0, timezone, locationId, params),
      apiGetAllCompletedTasksList(1, 0, timezone, locationId, params),
    ])
    .then((i) => {
      return {
        active: {
          count: i[0].data.meta.count || 0,
          title: "Active",
        },
        completed: {
          count: i[1].data.meta.count || 0,
          title: "Completed",
        },
      } as TabsI;
    })
    .catch((err) => err);
};

export const getTaskViewData = (id: string, timezone: string, userId?: string) => {
  return axios
    .all([apiGetTaskData(id), apiGetNotesListByTask(id, userId)])
    .then((response) => {
      const notesList =
        response[1]?.data.data?.map(
          (i: {
            [x: string]: any;
            id: string;
            attributes: { created: string; note: { value: string } };
          }) => {
            return {
              id: i.id,
              date: i.attributes.created,
              content: i.attributes.note.value,
              author: response[1].data.included?.find(
                (author: { id: any }) => author.id === i.relationships.uid.data.id
              )?.attributes.field_full_name,
            };
          }
        ) || [];

      const productsList =
        response[0].data.included
          ?.filter((i: ResponseDto<any>) => i.type === "ups_product--ups_product")
          ?.map((i: { [x: string]: any; attributes: { label: any } }) => {
            return { title: i.attributes.label, id: i.id };
          }) || [];

      const location = response[0].data.included?.find(
        (i: ResponseDto<any>) => i.type === "ups_location--ups_location"
      );

      return {
        title: response[0].data.data.attributes.label,
        location: {
          title: location?.attributes.label,
          address: location?.attributes.address,
          id: location?.id,
        },
        status: {
          name: getTaskStatus(response[0].data.data.attributes.completion_date, timezone),
          date: response[0].data.data.attributes.completion_date,
        },
        description: response[0].data.data.attributes.description?.value || "",
        products: productsList,
        notes: {
          items: notesList,
          count: response[1]?.data.meta.count || 0,
        },
        authorId: response[0].data.data.relationships.uid.data.id,
        deadline: response[0].data.data.attributes.deadline,
        priority: response[0].data.data.attributes.priority,
      } as TaskDetailsI;
    })
    .catch((err) => err);
};

export const getTaskData = (id: string) => {
  return apiGetTaskData(id)
    .then((response) => {
      const productsList =
        response.data.included
          ?.filter((i: ResponseDto<any>) => i.type === "ups_product--ups_product")
          ?.map((i: { [x: string]: any; attributes: { label: any } }) => {
            return { title: i.attributes.label, id: i.id };
          }) || [];

      const location = response.data.included?.find(
        (i: ResponseDto<any>) => i.type === "ups_location--ups_location"
      );
      return {
        title: response.data.data.attributes.label,
        location: {
          title: location?.attributes.label,
          address: location?.attributes.address,
          id: location?.id,
        },
        description: response.data.data.attributes.description?.value || "",
        deadline: response.data.data.attributes.deadline,
        priority: response.data.data.attributes.priority,
        products: productsList,
        authorId: response.data.data.relationships.uid.data.id,
      } as TaskDetailsI;
    })
    .catch((err) => err);
};

export const editTaskData = (id: string, body: TaskCreateFormI) => {
  return apiEditTaskData(id, {
    data: {
      type: "ups_tasks--ups_tasks",
      id: id,
      attributes: {
        label: body.title,
        description: body.description,
        deadline: moment(body.deadline).format("YYYY-MM-DD"),
        priority: body.priority,
      },
      relationships: {
        location: {
          data: {
            type: "ups_location--ups_location",
            id: body.location,
          },
        },
        products: {
          data: body.products.map((i) => {
            return {
              type: "ups_product--ups_product",
              id: i,
            };
          }),
        },
      },
    },
  }).catch((err) => err);
};

export const completeTask = (id: string, timezone: string) => {
  return apiEditTaskData(id, {
    data: {
      type: "ups_tasks--ups_tasks",
      id: id,
      attributes: {
        completion_date: moment().utc().tz(timezone).format("YYYY-MM-DDTHH:mm:ssZZ"),
      },
    },
  }).catch((err) => err);
};

export const deleteTask = (id: string) => {
  return apiDeleteTask(id).catch((err) => err);
};

export const createTask = (body: TaskCreateFormI) => {
  return apiCreateTask({
    data: {
      type: "ups_tasks--ups_tasks",
      attributes: {
        label: body.title,
        description: body.description,
        deadline: moment(body.deadline).format("YYYY-MM-DD"),
        priority: body.priority,
      },
      relationships: {
        location: {
          data: {
            type: "ups_location--ups_location",
            id: body.location,
          },
        },
        products: {
          data: body.products.map((i) => {
            return {
              type: "ups_product--ups_product",
              id: i,
            };
          }),
        },
      },
    },
  })
    .then((response) => {
      if (body.note) {
        return apiCreateNote({
          data: {
            type: "ups_note--ups_note",
            attributes: {
              note: body.note,
            },
            relationships: {
              location: {
                data: {
                  type: "ups_location--ups_location",
                  id: body.location,
                },
              },
              task: {
                data: {
                  type: "ups_task--ups_task",
                  id: response.data.data.id,
                },
              },
            },
          },
        }).then(() => response);
      } else {
        return response;
      }
    })
    .catch((err) => err);
};

export const getTaskName = (id: string) => {
  return apiGetTaskName(id)
    .then((resp) => {
      const response = formatResponseWarning(resp.data);
      return {
        id: response.data.id,
        name: response.data.attributes.label,
        locationId: response.data.relationships.location.data.id,
      };
    })
    .catch((err) => err);
};
