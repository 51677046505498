import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LocationsForm from "components/Shared/Locations/LocationsForm/LocationsForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createLocation } from "shared/Services";

import { LocationFormI } from "@interfaces";

import styles from "./LocationCreate.module.scss";
import { toast } from "react-toastify";

function LocationCreate() {
  const navigate = useNavigate();
  const params = useParams();

  const [submitted, setSubmitted] = useState(0);

  const FormSubmit = (value: LocationFormI) => {
    createLocation(value).then((response: AxiosError | any) => {
      if (axios.isAxiosError(response)) {
        toast.error(response.message);
      } else {
        toast.success("Successfully completed!");
        navigate(`/locations/${response}`);
      }
    });
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Create Location"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background="transparent"
        onCancelToggle={() => navigate(-1)}
      />
      <LocationsForm
        companyId={params.companyId}
        submitted={submitted}
        formFinished={FormSubmit}
      ></LocationsForm>
    </div>
  );
}

export default LocationCreate;
