import axios, { AxiosError } from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpRouter from "components/Router";
import LoadingSplash from "components/Shared/LoadingSplash/LoadingSplash";
import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import {
  getLoginStatus,
  getRoutingSettings,
  getSessionToken,
  getUserInfo,
} from "shared/Services";
import { RoutingSettingsI } from "@interfaces";
import Layout from "../../Layout/Layout";
import Auth from "../Auth/Auth";
import { oneTimeLogin } from "shared/Services/Auth.service";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const [currentUser, setCurrentUser] = useState<UserDataContextI | null>(null);

  const login = (userData: UserDataContextI) => {
    setIsLoading(true);
    check(userData);
  };
  const logout = () => {
    setIsLoading(true);
    check();
  };

  const check = (userData?: UserDataContextI) => {
    getLoginStatus().then((i) => {
      if (axios.isAxiosError(i)) {
        return;
      }
      getSessionToken().then(() => {
        let userInfo = userData as UserDataContextI | null;
        if (!!i.data) {
          if (!userInfo) {
            const userDataFromCookie = localStorage.getItem("current_user")!;
            userInfo = JSON.parse(userDataFromCookie) as UserDataContextI;
          }

          getUserInfo().then((info) => {
            if (axios.isAxiosError(info)) {
              toast.error(
                info.response?.data?.errors
                  ? info.response?.data?.errors[0]?.detail
                  : "See App.tsx 51"
              );
              return;
            }
            const permissions: any[] = [];
            info.data.roles.map((i: { [key: string]: string[] }) => {
              permissions.push(...Object.values(Object.values(i)[0]));
            });
            const roles: any[] = [];
            info.data.roles.map((i: any) => roles.push(...Object.keys(i)));

            userInfo = {
              ...userInfo!,
              uid: info.data.uuid.value,
              permissions,
              agentProfiles:
                info.data.agent_profiles.length || info.data.agent_profiles.target_id,
              roles,
              timezone: info.data.timezone,
            };

            if (info.data.agent_profiles.length || info.data.agent_profiles.target_id) {
              getRoutingSettings(info.data.uuid.value).then(
                (settings: AxiosError | RoutingSettingsI) => {
                  if (axios.isAxiosError(settings)) {
                    toast.error((settings.response?.data as any)?.errors[0]?.detail);
                  } else {
                    userInfo!.routerSettings = settings;
                    setCurrentUser(userInfo);
                    setIsLoading(false);
                  }
                }
              );
            } else {
              setCurrentUser(userInfo);
              setIsLoading(false);
            }
          });
        }

        if (!i.data) {
          setCurrentUser(null);
          localStorage.removeItem("csrf_token");
          localStorage.removeItem("current_user");
          localStorage.removeItem("session_token");
          setIsLoading(false);
        }
      });
    });
  };

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search));
    if (
      window.location.pathname === "/" &&
      searchParams.uid &&
      searchParams.timestamp &&
      searchParams.hash
    ) {
      oneTimeLogin({
        ...(searchParams as {
          uid: string;
          timestamp: string;
          hash: string;
        }),
      }).then((resp) => {
        if (axios.isAxiosError(resp)) {
          toast.error(resp?.response?.data.message || "Request failed.");
          check();
        } else {
          if (resp.data.error) {
            toast.error(resp.data.error);
          }

          resp.data.csrf_token &&
            localStorage.setItem("csrf_token", resp.data.csrf_token);
          if (resp.data.current_user) {
            localStorage.setItem("current_user", JSON.stringify(resp.data.current_user));
            login(resp.data?.current_user);
          } else {
            check();
          }
        }
      });
    } else {
      check();
    }
  }, []);

  return (
    <UserDataContext.Provider value={{ currentUser, setCurrentUser, login, logout }}>
      <LoadingSplash isLoading={isLoading}>
        <Router>
          {!!currentUser ? (
            <Layout>
              <UpRouter />
            </Layout>
          ) : (
            <Auth />
          )}

          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Router>
      </LoadingSplash>
    </UserDataContext.Provider>
  );
}

export default App;
