import { useEffect, useState } from "react";

import styles from "./ScrollablePicker.module.scss";

interface PickerColumnPropsI {
  options: { value: string; label: string }[];
  name: string;
  value: string;
  itemHeight: number;
  columnHeight: number;
  onChange: (name: string, value: string) => void;
  disable?: boolean;
}

interface UpTimePickerPropsI {
  optionGroups: { [key: string]: { value: string; label: string }[] };
  valueGroups: { [key: string]: string };
  onChange: (name: string, value: string) => void;
  itemHeight?: number;
  height?: number;
  disable?: boolean;
}

function PickerColumn({
  options,
  name,
  value,
  itemHeight,
  columnHeight,
  onChange,
  disable = false,
}: PickerColumnPropsI) {
  const [isMoving, setIsMoving] = useState(true);
  const [startTouchY, setStartTouchY] = useState(0);
  const [startScrollerTranslate, setStartScrollerTranslate] = useState(0);
  const [scrollerTranslate, setScrollerTranslate] = useState(0);
  const [selectedItem, setSelectedItem] = useState(value);

  const minTranslate = columnHeight / 2 - itemHeight * options.length + itemHeight / 2;
  const maxTranslate = columnHeight / 2 - itemHeight / 2;

  const selectingCompleted = (newValue: string) => {
    setSelectedItem(newValue);
    let selectedIndex = 0;
    Object.values(options.map((i) => i.value)).forEach((name, index) => {
      if (name === newValue) {
        selectedIndex = index;
      }
    });
    setScrollerTranslate(columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight);
    onChange(name, newValue);
  };

  useEffect(() => {
    selectingCompleted(value);
  }, [value]);

  const handleTouchStart = (event: any) => {
    if (disable) {
      return;
    }
    setStartTouchY(event.targetTouches[0].pageY);
    setStartScrollerTranslate(scrollerTranslate);
  };

  const handleTouchMove = (event: any) => {
    if (disable) {
      return;
    }
    const touchY = event.targetTouches[0].pageY;
    setIsMoving(true);

    let nextScrollerTranslate = startScrollerTranslate + touchY - startTouchY;
    if (nextScrollerTranslate < minTranslate) {
      nextScrollerTranslate =
        minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
    } else if (nextScrollerTranslate > maxTranslate) {
      nextScrollerTranslate =
        maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
    }
    setScrollerTranslate(nextScrollerTranslate);
  };

  const handleTouchEnd = () => {
    if (!isMoving) {
      return;
    }
    setIsMoving(false);
    setStartTouchY(0);
    setStartScrollerTranslate(0);

    setTimeout(() => {
      let activeIndex;
      if (scrollerTranslate > maxTranslate) {
        activeIndex = 0;
      } else if (scrollerTranslate < minTranslate) {
        activeIndex = options.length - 1;
      } else {
        activeIndex = -Math.floor((scrollerTranslate - maxTranslate) / itemHeight);
      }
      selectingCompleted(options[activeIndex].value);
    }, 0);
  };

  const handleTouchCancel = () => {
    if (!isMoving) {
      return;
    }

    setIsMoving(false);
    setStartTouchY(0);
    setScrollerTranslate(startScrollerTranslate);
    setStartScrollerTranslate(0);
  };

  const handleItemClick = (option: any) => {
    if (option !== value) {
      selectingCompleted(option);
    }
  };

  return (
    <div className={styles["picker-column"]}>
      <div
        className={styles["picker-scroller"]}
        style={{
          msTransform: `translate3d(0, ${scrollerTranslate / 16}rem, 0)`,
          OTransform: `translate3d(0, ${scrollerTranslate / 16}rem, 0)`,
          WebkitTransform: `translate3d(0, ${scrollerTranslate / 16}rem, 0)`,
          transform: `translate3d(0, ${scrollerTranslate / 16}rem, 0)`,
          transitionDuration: isMoving ? "0ms" : "300ms",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchCancel}
      >
        {options.map((option, index) => (
          <div
            key={index}
            className={`${styles["picker-item"]} ${
              option.value === selectedItem ? styles["picker-item-selected"] : ""
            }`}
            style={{
              height: itemHeight + "px)",
              lineHeight: itemHeight + "px)",
            }}
            onClick={() => handleItemClick(option.value)}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}
function UpTimePicker({
  optionGroups,
  valueGroups,
  onChange,
  itemHeight = 36,
  height = 216,
  disable = false,
}: UpTimePickerPropsI) {
  return (
    <div
      className={styles["picker-container"]}
      style={{ height: height / 16 + "rem", opacity: disable ? "0.7" : "1" }}
    >
      <div className={styles["picker-inner"]}>
        <>
          {Object.keys(optionGroups).map((name, index) => (
            <PickerColumn
              key={index}
              name={name}
              options={optionGroups[name]}
              value={valueGroups[name]}
              itemHeight={itemHeight}
              columnHeight={height}
              onChange={onChange}
              disable={disable}
            />
          ))}
        </>
        <div
          className={styles["picker-highlight"]}
          style={{
            height: itemHeight,
            marginTop: -(itemHeight / 2),
          }}
        ></div>
      </div>
    </div>
  );
}

export default UpTimePicker;
