import { useContext } from "react";
import { UserDataContext } from "../../../shared/Contexts";
import { Route, Routes } from "react-router-dom";
import AgentsListView from "./AgentsListView/AgentsListView";
import { Roles } from "../../../shared/Enums";
import { ErrorBoundary } from "error";

export default function AgentsRouter() {
  const { currentUser } = useContext(UserDataContext);

  return (
    <Routes>
      {currentUser?.roles.includes(Roles.manager) && (
        <>
          <Route
            path="/"
            element={
              <ErrorBoundary>
                <AgentsListView />
              </ErrorBoundary>
            }
          />
        </>
      )}
    </Routes>
  );
}
