import { CountriesStatesContextI } from "shared/Contexts/CountriesStatesContext";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import { PermissionsList, Roles, TaskStatuses } from "shared/Enums";

import { AddressDto } from "@interfaces";

const moment = require("moment-timezone");

export const GetStateOrCountryCode = (
  name: string,
  list: CountriesStatesContextI
): { states: string[]; countries: string[] } => {
  const states: string[] = [];
  const countries: string[] = [];
  for (const [key, value] of Object.entries(list)) {
    if (value.name.toLowerCase().includes(name.toLowerCase())) {
      countries.push(key);
    }
    for (const state of value.states) {
      if (state.state.toLowerCase().includes(name.toLowerCase())) {
        states.push(state.state_code);
      }
    }
  }
  return { states, countries };
};

export const formatAddressToString = (
  address: AddressDto | null,
  list: CountriesStatesContextI
): string => {
  if (!address) return "";
  const countryName = list[address.country_code]?.name;
  const stateName = list[address.country_code]?.states.find(
    (i) => i.state_code === address.state
  )?.state;

  return ` ${address.address_line && address.address_line + ", "} ${
    address.postal_code && address.postal_code + ", "
  } ${address.city && address.city + ", "}  ${
    stateName && stateName + ", "
  } ${countryName}`;
};

export const getTaskStatus = (date: string | null, timezone: string): TaskStatuses => {
  const now = moment().utc().tz(timezone);
  const sentDate = date ? moment(date) : null;
  if (sentDate && sentDate.isSameOrBefore(now)) {
    return TaskStatuses.completed;
  }
  return TaskStatuses.active;
};

export const formatResponseWarning = (value: string): any => {
  if (typeof value === "string") {
    const index = value.indexOf(`{"jsonapi":{"`);

    if (index !== -1) {
      return JSON.parse(value.substring(index));
    }
  }
  return value;
};

export const isUserHavePermissions = (
  user: UserDataContextI,
  permission: PermissionsList[]
): boolean => {
  let result = false;
  permission.forEach((i) => {
    if (user!.permissions.includes(i)) {
      result = true;
    }
  });
  return result;
};

export const CheckRoutingPermission = (user: UserDataContextI): boolean => {
  return !!(user!.roles.includes(Roles.agent) && user!.agentProfiles);
};

export const checkPermissionsForEdit = (
  user: UserDataContextI,
  all: PermissionsList,
  permission: PermissionsList,
  authorId: string
): boolean => {
  let result = false;
  if (
    user!.permissions.includes(all) ||
    (user!.permissions.includes(permission) && authorId === user!.uid)
  ) {
    result = true;
  }
  return result;
};

export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const earthRadius = 6371 * 1000;

  const toRadians = (degrees: number) => {
    return degrees * (Math.PI / 180);
  };

  const deltaLat = toRadians(lat2 - lat1);
  const deltaLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;
  return Math.floor(distance);
};

export const checkDateValidation = (dateFrom: string, dateTo: string) => {
  return moment(dateFrom, "hh:mm A").isBefore(moment(dateTo, "hh:mm A"));
};
