import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import LocationsList from "components/Shared/Locations/LocationsList/LocationsList";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./LocationsListView.module.scss";
import { ManagerContext } from "shared/Contexts";

function LocationsListView() {
  const navigate = useNavigate();
  const [{ agent }] = useContext(ManagerContext);
  const [isLoading, setIsLoading] = useState(false);

  const reload = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  };

  const headerTitle = (
    <div className={agent ? styles["up-header--agent-mode"] : ""}>
      {agent ? (
        <>
          <div>Locations of</div>
          <div>{agent.fullName}</div>
        </>
      ) : (
        "Locations"
      )}
    </div>
  );

  return (
    <div className={styles["up-container"]}>
      <Header
        title={headerTitle}
        toggleBack={() => navigate("/")}
        toggleReload={reload}
      />
      <div className={styles["up-locations"]}>
        <LoadingSpinner isLoading={isLoading}>
          <LocationsList
            hasSearch={true}
            locationSelected={(location) => navigate(`/locations/${location.id}`)}
          />
        </LoadingSpinner>
      </div>
    </div>
  );
}

export default LocationsListView;
