import { Route, Routes } from "react-router-dom";
import ViewReports from "./ViewReports/ViewReports";
import { ErrorBoundary } from "error";

export default function ReportsRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <ViewReports />
          </ErrorBoundary>
        }
      />
    </Routes>
  );
}
